.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.nav--desktop {
  display: block;
  width: 100%;
  @media screen and (max-width: 700px) {
    display: none;
  }
}

.nav--mobile {
  display: none;
  @media screen and (max-width: 700px) {
    width: 100%;
    display: block;
  }

  .navbar__logo {
    margin-top: 5px;
  }
}

.nav__item {
  position: relative;
  white-space: nowrap;
  &.active {
    > .nav__link {
      color: $ros-orange;
    }
  }

  &:hover {
    .nav__dropdown {
      display: block;
    }
  }
}

div.nav__link {
  cursor: default;
}

.nav__link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #ffffff;
  transition: color 125ms;
  font-size: 1.25rem;
  font-weight: 600;
  position: relative;

  &:hover {
    color: $ros-orange;
    text-decoration: none;
  }
}

.light-background .nav__dropdown{
  background-color: $ros-light-grey;
}

.dark-background .nav__dropdown{
  background-color: $ros-dark-grey;
}

.nav__dropdown {
  display: none;
  position: absolute;
  left: 0;
  z-index: 9999999999;
}
