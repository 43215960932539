* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html {
  &.light-background {
    background-color: $ros-light-grey;
  }

  &.dark-background {
    background-color: $ros-dark-grey;
  }
}

.light-background {
  background-color: $ros-light-grey;
}

.dark-background {
  background-color: $ros-dark-grey;
}

.site-wrapper {
  min-height: calc(100vh - #{$footer-height});
}

.page-content {
  min-height: calc(100vh - #{$footer-height});
}

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffffff;
  text-align: left;
}

img {
  max-width: 100%;
}

.page-content {
  padding-bottom: 4rem;
  padding-top: 100px;
  @media screen and (min-width: 768px) {
    padding-top: 200px;
  }
}

.divider {
  width: 100%;
  display: block;
  height: 2px;
  background-color: $ros-white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  @media screen and (min-width: 768px) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
}

.divider--no-margin {
  margin-top: 0;
  margin-bottom: 0;
}

.divider--low-margin {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.divider--medium-margin {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.divider--large-margin {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.section-title {
  width: 100%;
  color: $ros-orange;
  font-size: 1.5rem;
  font-weight: 600;
}

.section-title--white {
  color: #ffffff;
}

.section-title--right {
  text-align: right;
}

.portfolio-logo {
  background-color: $ros-light-grey;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  margin: 3rem 0;
  @media screen and (min-width: 768px) {
    min-height: 200px;
  }
}

.portfolio-logo__logo {
  max-width: 100%;
}

.industries-list {
  list-style: none;
  padding: 0;
}

pre {
  margin: 30px 0 40px 0;
  color: #c5c8c6;
  background-color: #191919;
  border-radius: 2px;
  padding: 25px;
  overflow-x: auto;
}

pre code {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  display: block;
  line-height: 1.6;
  overflow-x: auto;
  white-space: pre;
}
