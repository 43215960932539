$color-main: #FFF;
$color-active: #FFF;

$button-height: 27px;
$button-width: 35px;

.hamburger-button-wrapper {
  padding: 20px;
  position: relative;
  z-index: 999999;
  cursor: pointer;
}

.hamburger-button {
  margin-top: 20px;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  position: relative;
  z-index: 99999;
  transition: opacity .25s ease;

  &:hover {
    opacity: .7;
  }

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: $color-active;
    }

    .middle {
      opacity: 0;
      background: $color-active;
    }

    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: $color-active;
    }
  }

  span {
    background: $color-main;
    border: none;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }
}
