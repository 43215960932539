// Navbar
$navbar-height: 125px;

// Footer
$footer-height: 60px;

// Colors
$ros-orange: #db6a26;
$ros-white: #a4a4a4;
$ros-light-grey: #373737;
$ros-dark-grey: #242424;
$ros-near-white: #949494;

