.navbar {
  display: flex;
  width: 100%;
  position: fixed;
  z-index: 100;
  align-items: center;
  top: 0;
  @media screen and (min-width: 768px) {
    position: fixed;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
}

.nav-bar--transparent {
  background-color: transparent!important;
}

.light-background .navbar {
  background-color: $ros-light-grey;
}

.dark-background .navbar {
  background-color: $ros-dark-grey;
}

.navbar__logo {
  width: auto;
  height: 100%;
}

.navbar__logo-wrapper {
  position: relative;
  z-index: 100;
}
