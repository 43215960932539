.page-banner {
  @media screen and (min-width: 768px){
    padding-bottom: 8rem;
  }
}

.page-banner--text-center {
  text-align: center;
}

.page-banner--news {
  @media screen and (min-width: 768px){
    padding-bottom: 1rem;
  }
}

.page-banner__title {
  font-size: 4rem;
  font-weight: 600;
  font-family: 'Source Serif Pro', serif;
  margin: 0;
  @media screen and (min-width: 768px) {
    font-size: 4.5rem;
  }
}

.page-banner__title--news {
  font-size: 3.5rem;
}

.page-banner__text {
  margin: 0;
  font-size: 1.8rem;
  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
}

.page-banner__text--news {
  font-size: 1.5rem;
}
