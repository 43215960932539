.blog-categories__list {
  display: flex;
  flex-direction: column;
}

.blog-categories__links {
  color: #ffffff;
  text-decoration: none;
  padding: 5px 0;
}

.blog-list {
  display: flex;
  flex-direction: column;
}

.blog-featured-post__link {
  font-size: 1.5rem;
  color: #ffffff;
  text-decoration: none;
  padding: 5px 0;
}
