.posts__wrapper {
  padding-top: 5rem;
}

.posts-paginator {
  padding: 0;
}

.posts-paginator__item {
  display: inline-block;
  &.selected .posts-paginator__link {
      font-weight: bold;
      color: $ros-orange!important;
  }
}

.posts-paginator__link {
  color: white;
}

.posts-paginator__wrapper {
  text-align: center;
}
