.list-style-none {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.overflow-hidden {
  overflow: hidden;
}

.w-100 {
  width: 100%;
}

.center {
  text-align: center;
}

.border-top {
  border-top: 1px solid white;
}

.border-bottom {
  border-bottom: 1px solid white;
}

.pt-6 {
  padding-top: 6rem;
}

.pb-6 {
  padding-bottom: 6rem;
}

.mb-6 {
  margin-bottom: 6rem;
}

.right {
  text-align: right;
}
