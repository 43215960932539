.error-page__image {
  width: 100%;
  display: inline-block;
  @media screen and (min-width: 767px) {
    max-width: 250px;
    float: right;
  }
  @media screen and (min-width: 991px) {
    max-width: 430px;
    float: right;
  }
}
