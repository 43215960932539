.public-report {
  padding: 20px 0;
  border-top: 2px $ros-white solid;

  &:last-child {
    border-bottom: 2px $ros-white solid;
  }
}

.public-report a {
  font-style: italic;
}

.public-report.resource a {
  font-style: normal;
}

.public-report__title {
  font-size: 1.5rem;
  font-weight: bold;
  color: $ros-orange;
}

.public-report__logo {
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  justify-content: center;
  & img {
    height: 100%;
    width: auto;
  }
}

.public-report__button {
  display: inline-block;
  border: 3px solid $ros-orange;
  color: white;
  padding: 16px;
  min-width: 200px;
  text-align: center;
  float: right;
  &:hover {
    background-color: $ros-orange;
  }
  @media screen and (max-width: 767px) {
    float: none;
  }
}
