.contact-info {
  height: 100%;
  font-size: 1.5rem;
}

.contact-info__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.contact-info__row {
  height: 100%;
  padding: 1rem 0;
  align-content: space-between;
  border-top: 2px #a4a4a4 solid;

  &:last-child {
    border-bottom: 2px #a4a4a4 solid;
  }

  @media screen and (min-width: 768px) {
    padding-top: 2rem;
  }
}
