.home-page {
  //background-image: url('/assets/images/roshome-page-background-stairs.png');
  height: 50vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media screen and (min-width: 768px) {
    height: 100vh;
  }

  @media screen and (max-height: 450px) {
    height: 100%;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
}

.home-page__mobile_container {
  display: block;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.home-page__desktop_container {
  //max-width: 1400px;
  margin: 0 auto;
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.home-page__container {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.home-page__jumping-man {
  width: 50px;
}


.home-page__text-container {
  @media screen and (max-height: 450px) {
    padding-top: 300px;
  }
}

.home-page__title {
  font-size: 2.5rem;
  font-weight: 600;
  font-family: 'Source Serif Pro', serif;
  position: relative;
  z-index: 1;
  @media screen and (min-width: 768px) {
    font-size: 4.5rem;
  }
}

.home-page__text {
  font-size: 1.2rem;
  text-align: center;
  position: relative;
  z-index: 1;
  @media screen and (min-width: 768px) {
    font-size: 2rem;
    text-align: left;
  }
}
