$source-serif-pro-font-location: '../fonts/source-serif-pro/';
$source-sans-pro-font-location: '../fonts/source-sans-pro/';

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 200;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-ExtraLight.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-ExtraLight.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-ExtraLight.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 200;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-ExtraLightIt.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-ExtraLightIt.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-ExtraLightIt.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-ExtraLightIt.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-Light.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-Light.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-Light.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 300;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-LightIt.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-LightIt.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-LightIt.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-LightIt.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-Regular.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-Regular.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-Regular.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 400;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-It.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-It.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-It.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-It.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-Semibold.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-Semibold.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-Semibold.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 600;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-SemiboldIt.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-SemiboldIt.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-SemiboldIt.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-SemiboldIt.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-Bold.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-Bold.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-Bold.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 700;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-BoldIt.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-BoldIt.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-BoldIt.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-BoldIt.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 900;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-Black.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-Black.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-Black.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Serif Pro';
  font-weight: 900;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-serif-pro-font-location + 'WOFF2/TTF/SourceSerifPro-BlackIt.ttf.woff2') format('woff2'),
  url($source-serif-pro-font-location + 'WOFF/OTF/SourceSerifPro-BlackIt.otf.woff') format('woff'),
  url($source-serif-pro-font-location + 'OTF/SourceSerifPro-BlackIt.otf') format('opentype'),
  url($source-serif-pro-font-location + 'TTF/SourceSerifPro-BlackIt.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-ExtraLight.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-ExtraLight.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-ExtraLight.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 200;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-ExtraLightIt.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-ExtraLightIt.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-ExtraLightIt.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-ExtraLightIt.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-Light.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-Light.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-Light.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 300;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-LightIt.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-LightIt.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-LightIt.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-LightIt.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-Regular.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-Regular.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-Regular.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 400;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-It.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-It.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-It.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-It.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-Semibold.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-Semibold.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-Semibold.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 600;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-SemiboldIt.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-SemiboldIt.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-SemiboldIt.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-SemiboldIt.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-Bold.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-Bold.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-Bold.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-BoldIt.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-BoldIt.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-BoldIt.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-BoldIt.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 900;
  font-display: swap;
  font-style: normal;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-Black.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-Black.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-Black.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-weight: 900;
  font-display: swap;
  font-style: italic;
  font-stretch: normal;
  src: url($source-sans-pro-font-location + 'WOFF2/TTF/SourceSansPro-BlackIt.ttf.woff2') format('woff2'),
  url($source-sans-pro-font-location + 'WOFF/OTF/SourceSansPro-BlackIt.otf.woff') format('woff'),
  url($source-sans-pro-font-location + 'OTF/SourceSansPro-BlackIt.otf') format('opentype'),
  url($source-sans-pro-font-location + 'TTF/SourceSansPro-BlackIt.ttf') format('truetype');
}



