h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: $ros-orange;
}

h1 {
  font-size: 2.5rem
}

h2 {
  font-size: 2rem
}

h3 {
  font-size: 1.75rem
}

h4 {
  font-size: 1.5rem
}

h5 {
  font-size: 1.25rem
}

h6 {
  font-size: 1rem
}

p {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.font-weight-bold {
  font-weight: bold;
}

a {
  color: $ros-orange;
  text-decoration: none;
  word-break: break-all;
  &:hover {
    text-decoration: underline;
  }
}
