.post-card {
  position: relative;
  padding-bottom: 4rem;
}

.post-card__link-arrow {
  display: inline-block;
  max-width: 39px;
}

.post-card--title {
  font-size: 1.5rem;
  color: $ros-orange;
  font-weight: 600;
}

.post-card__image {
  width: 100%;
}

.post-card__link-anchor {
  display: inline-block;
  word-break: keep-all;
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.post-card__stretched-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0);
  }
}
