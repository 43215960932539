.team-member__wrapper {
  display: flex;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.team-member__card {
  margin: 0 15px 20px;
  flex: 0 1 calc(50% - 30px);
  @media screen and (min-width: 768px) {
    margin-bottom: 30px;
    flex: 0 1 calc(20% - 30px);
  }

  transition: all 125ms ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.team-member__image {
  padding-top: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.team-member__link {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.team-member-page__title {
  font-size: 3rem;
  margin-top: 0;
  font-weight: 600;
  font-family: 'Source Serif Pro', serif;
  @media screen and (min-width: 768px) {
    font-size: 4.5rem;
  }
}

.team-member-page__image-mobile {
  max-width: calc(50% - 10px);
  margin-right: 10px;
  margin-top: 1rem;
  float: left;
}

.team-member-page__desktop {
  display: none;
  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.team-member-page__mobile {
  display: flex;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .ros-skill-1 {
    max-width: 155px;
  }

  .ros-skill-2 {
    max-width: 190px;
  }

  .ros-skill-3 {
    max-width: 180px;
  }
}
