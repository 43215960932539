.ros-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}

.ros-list--5 .ros-list__item {
  flex: 0 1 100%;
  @media screen and (min-width: 768px) {
    flex: 0 1 20%;
  }
}

.ros-list--3 .ros-list__item {
  padding: 0;
  flex: 0 1 100%;
  @media screen and (min-width: 768px) {
    flex: 0 1 33.333333333%;
  }
}

.ros-list__item {
  margin-bottom: 20px;
  padding: 0 15px;
}

.ros-list__title {
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
}

.ros-list__title--uppercase {
  text-transform: uppercase;
}

.ros-list__index {
  font-size: 1.2rem;
  font-weight: bold;
  color: $ros-orange;
}
