.highlights {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  .highlight {
    width: calc(33.3% - 16px);
    &:hover {
      .highlight-title {
        color: $ros-orange;
      }
    }
    @media screen and (max-width: 992px) {
      width: calc(50% - 16px);
    }
  }
  .highlight-title {
    text-decoration: underline;
    word-break: keep-all;
  }
}
