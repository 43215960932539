.post__title {
  margin-bottom: 1rem;
  @media screen and (min-width: 768px) {
    margin-bottom: 5rem;
  }
}

.post__image {
  width: 100%;
}

.post-details {
  //width: 100%;
  //display: none;
  //@media screen and (min-width: 768px) {
  //  display: flex;
  //}
}

.post-details__block {
  margin-bottom: 2rem;
  text-align: center;
  width: 100%;
  @media screen and (min-width: 768px) {
    text-align: left;
  }
}

.post-details__block--no-mobile {
  display: none;
  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.post-details__title {
  display: none;
  @media screen and (min-width: 768px) {
    display: flex;
  }
}

.post-details__block-title {
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
}

.post-details__details {
  padding: 0.5rem 0;
}

.post-details__details--small-muted {
  font-size: 0.85rem;
  opacity: 0.7;
}
