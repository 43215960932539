.footer {
  width: 100%;
  height: auto;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  @media screen and (min-width: 600px) {
    height: $footer-height;
  }
}

.footer__top {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: 0 16px 5px;
  @media screen and (min-width: 700px) {
    padding: 0 50px 5px;
  }
  .left {
    > .footer-link {
      margin-right: 24px;
    }
  }
  .right {
    > .footer-link {
      margin-right: 24px;
    }
    @media screen and (min-width: 525px) {
      margin-left: auto;
      > .footer-link {
        margin-left: 24px;
        margin-right: 0;
      }
    }
  }
}

.footer__bottom {
  background-color: #191919;
  display: flex;
  justify-content: center;
  padding: 0;
  line-height: 1;
  font-size: 0.7rem;
  color: $ros-light-grey;
  @media screen and (min-width: 768px) {
    padding: 0 50px;
    justify-content: end;
  }
}

.footer__email-address {
  color: $ros-orange;
  text-decoration: none;
}

.footer__social-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: bottom;
  margin-right: 0.5rem;
  > img {
    width: auto;
    height: 100%;
  }
}

.footer__email-address--grey {
  color: $ros-light-grey;
}

.footer-link {
  font-size: 1rem;
  font-weight: 600;
}

.footer-link--grey {
  color: $ros-near-white;
}

.footer__pipe {
  margin: 0 5px;
}
