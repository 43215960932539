.nav-mobile {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: $ros-dark-grey;
  z-index: 99;
  visibility: hidden;
  opacity: 0;
  &.open {
    visibility: visible;
    transition: opacity 250ms;
    opacity: 1;
  }
}

.nav-mobile__wrapper {
  height: 100vh;
  width: 100%;
  margin-top: 75px;
  padding-bottom: 90px;
  overflow: auto;
}

.nav-mobile__link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #ffffff;
  transition: color 125ms;
  font-size: 1.25rem;
  font-weight: 600;

  &:hover {
    color: $ros-orange;
    text-decoration: none;
  }
}

.nav-mobile__item {
  &.active {
    > .nav-mobile__link {
      color: $ros-orange;
    }
  }
}

.nav-mobile__items--child {
  padding-left: 20px;
}
