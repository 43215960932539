.tab-bar-container {
  @media screen and (max-width: 990px) {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
}

.tab-bar {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media screen and (min-width: 992px) {
    justify-content: flex-end;
  }
}

.tab-bar__item {
  position: relative;
  white-space: nowrap;
  margin: 0 10px;
  font-weight: bolder;
  font-size: 0.9rem;
  color: $ros-near-white;
  text-decoration: none;
  word-break: break-all;
  border: 3px solid transparent;
  border-radius: 3rem;
  padding: 8px 12px;
  opacity: 0.5;
  font-weight: 600;
  @media screen and (min-width: 1201px) {
    font-size: 1.25rem;
  }
  &.active {
    color: white;
    border: 3px solid $ros-orange;
    opacity: 1;
  }
}
